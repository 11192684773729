import React from 'react';
import { Image, ImageProps, useStyles, useToken } from '@chakra-ui/react';

export type IconProps = {
  image?: string;
  /**
   * SVGs are colored using css filters
   */
  color?:
    | `gray.${number}00`
    | 'red.500'
    | 'blue.500'
    | 'yellow.500'
    | 'orange.700'
    | 'pepperBlack';
} & Partial<Omit<ImageProps, 'color'>>;

// TODO: Add support for links in Icon. If no href is passed, do not wrap with <a>
/**
 * Render a fixed size icon as an Image from the shared icons directory.
 * Supports coloring using css filters via `color` prop and sets alt text as the icon name
 */
export const Icon = ({ image, color, src, ...rest }: IconProps) => {
  const width = rest.w || rest.width || 5;
  const [filter] = useToken('colors', [`filters.${color || 'gray.900'}`]);
  return (
    <Image
      alt={`${image} icon`}
      w={width}
      minW={width}
      h={width}
      minH={width}
      // !important because theme overrides style
      filter={color ? `${filter} !important` : undefined}
      {...rest}
      src={image ? `/shared/icons/${image}.svg` : src}
    />
  );
};

export const FormErrorIcon = (props: Partial<Omit<ImageProps, 'color'>>) => {
  const styles = useStyles().icon;

  return <Icon __css={styles} {...props} image="alert-solid" />;
};
