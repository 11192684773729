import { config } from '../config';
import { urlSearchParamsToHref } from '../helpers';

/**
 * If on client side then we will compare current host and add it to pages if not matched
 * On server always generate full url
 */
const host = (() => {
  const isBrowser = typeof window !== 'undefined';
  const url = config.ADMIN_CLIENT_URL;
  const configHostname = new URL(url).hostname;
  if (
    (isBrowser && globalThis.location.hostname === configHostname) ||
    (!isBrowser && config.SERVICE_NAME === 'gfs-pepper-admin')
  ) {
    // Use relative url
    return '';
  }
  return url;
})();

export const Admin = {
  FACILITY_LIST: `${host}/facilities`,
  FACILITY_CREATE: ({
    params = new URLSearchParams(),
  }: { params?: URLSearchParams } = {}) => {
    params.set('create', `true`);
    return `${host}/facilities${urlSearchParamsToHref(params)}`;
  },
  FACILITY_DETAILS: ({
    params = new URLSearchParams(),
    facilityId,
  }: {
    params?: URLSearchParams;
    facilityId: string;
  }) => `${host}/facilities/${facilityId}${urlSearchParamsToHref(params)}`,
  FACILITY_STALL_DETAILS: ({
    stallId,
    facilityId,
    params = new URLSearchParams(),
    tab,
  }: {
    stallId: string;
    facilityId: string;
    params?: URLSearchParams;
    tab?: 'display' | 'details';
  }) => {
    if (tab) {
      params.set('tab', tab);
    }
    return `${host}/facilities/${facilityId}/stalls/${stallId}${urlSearchParamsToHref(
      params,
    )}`;
  },
  STALL_GROUP: ({
    stallNumber,
    facilityId,
    params = new URLSearchParams(),
    tab,
  }: {
    stallNumber: number;
    facilityId: string;
    params?: URLSearchParams;
    tab?: 'display' | 'details';
  }) => {
    if (tab) {
      params.set('tab', tab);
    }
    return `${host}/facilities/${facilityId}/stall-groups/${stallNumber}${urlSearchParamsToHref(
      params,
    )}`;
  },

  // TODO: Add onboarding/offboarding pages

  VENDOR_CREATE_DRAWER: ({
    params = new URLSearchParams(),
  }: { params?: URLSearchParams } = {}) => {
    params.set('vendorAction', 'add');
    return urlSearchParamsToHref(params);
  },
  VENDOR_DETAILS_DRAWER: ({
    params = new URLSearchParams(),
    globalEntityId,
    vendorId,
  }: {
    params?: URLSearchParams;
    globalEntityId: string;
    vendorId: string;
  }) => {
    params.set('vendorAction', `edit`);
    params.set('vendorData', `${globalEntityId};${vendorId}`);
    return urlSearchParamsToHref(params);
  },

  PARTNER_LIST: `${host}/partners`,
  PARTNER_CREATE: ({
    params = new URLSearchParams(),
  }: { params?: URLSearchParams } = {}) => {
    params.set('create', `true`);
    return `${host}/partners${urlSearchParamsToHref(params)}`;
  },
  PARTNER_DETAILS: ({
    partnerId,
    params = new URLSearchParams(),
    tab,
  }: {
    partnerId: string;
    params?: URLSearchParams;
    tab?: 'stalls' | 'details';
  }) => {
    if (tab) {
      params.set('tab', tab);
    }
    return `${host}/partners/${partnerId}${urlSearchParamsToHref(params)}`;
  },
  PARTNER_STALL_DETAILS: ({
    stallId,
    partnerId,
  }: {
    stallId: string;
    partnerId: string;
  }) => `${host}/partners/${partnerId}/stalls/${stallId}`,

  PARTNER_MIGRATION_LIST: `${host}/partners/migration/list`,
  PARTNER_MIGRATION_DUPLICATES: (id: string) =>
    `${host}/partners/migration/list/${id}`,

  COUNTRY_LIST: `${host}/countries`,
  COUNTRY_CREATE: `${host}/countries/create`,
  COUNTRY_DETAILS: ({ countryId }: { countryId: string }) =>
    `${host}/countries/${countryId}`,

  USER_LIST: `${host}/users`,
  USER_CREATE: `${host}/users?create=true`,
  USER_DETAILS: ({ userId }: { userId: string }) => `${host}/users/${userId}`,
  USER_PROFILE: `${host}/users/profile`,

  VENDOR_LIST: `${host}/vendors`,
};
