import React, { createContext, useContext, useMemo } from 'react';
import { logger } from '@pepper/logger';
import { ParametersExceptFirst } from '@pepper/types';
import { useChangedFields } from '@deliveryhero/gfs-ui';
import { config } from '../../config';
import { usePageConfig } from '../usePageConfig';
import {
  CommonEventParameters,
  GtmAction,
  GtmCategory,
  pushGtmTrackEvent,
} from './gtm';

const GtmContext = createContext<{ category: GtmCategory } | null>(null);

type GtmCtx = NonNullable<React.ContextType<typeof GtmContext>>;

/**
 * Sets the GtmCategory for a component so when gtm actions are pushed via `useGtm` they will have this category prefix
 */
export function withGtm<C extends React.FC<any>>(Component: C, gtm: GtmCtx) {
  const res = (props: React.ComponentProps<C>) => {
    return (
      <GtmContext.Provider value={gtm}>
        <Component {...props} />
      </GtmContext.Provider>
    );
  };
  return res;
}

/**
 * Used to push gtm events based on the component's context:
 * - if a component is used at page level, then it will take page's GtmCategory
 * - if it is used inside a section that's wrapped in `withGtm` then that section's category will be used
 *
 * This allows us to track both feature usage (using just action and label) and from where is that feature used (the category)
 *
 * @example Check this PR - https://github.com/deliveryhero/gfs-kitchen-screens/pull/2054
 */
export const useGtm = ({
  id,
  info,
  entity,
  value,
}: Pick<CommonEventParameters, 'id' | 'entity' | 'info' | 'value'> = {}) => {
  const [{ gtm: pageGtm }] = usePageConfig();
  const { category: pageCategory } = pageGtm ?? {};
  const { category: componentCategory } = useContext(GtmContext) ?? {};

  const category = componentCategory || pageCategory;
  if (!category && config.APP_ENV === 'local') {
    throw new Error('Please define GTM category at Page/Component level');
  }

  return useMemo(() => {
    /**
     * @example
     * If action = "opened", then the label would be what is opened.
     * ```ts
     * pushGtm('opened', {
     *   label: "Add Vendor",
     * });
     * ```
     *
     * @example
     * If action = "succeeded", then the label would be what action succeeded
     * ```ts
     * pushGtm('succeeded', {
     *   label: "Cancel Schedule",
     *   info: "Onboarding",
     * });
     * ```
     */
    const pushGtm = (
      action: GtmAction,
      ...params: ParametersExceptFirst<typeof pushGtmTrackEvent>
    ) => {
      if (!category) {
        logger.error('Please define GTM category at Page/Component level');
      }

      const [first, ...rest] = params;
      return pushGtmTrackEvent(
        `${category || 'unknown'}.${action}`,
        {
          id,
          info,
          entity,
          value,
          ...first,
        },
        ...rest,
      );
    };

    const pushGtmOnChangeFields: Parameters<
      typeof useChangedFields
    >['0']['onChange'] = ({
      added,
      changed,
      removed,
    }: {
      added: [key: string, val: unknown][];
      changed: [key: string, val: unknown][];
      removed: [key: string, val: unknown][];
    }) => {
      const present = [...added, ...changed];
      const [, searchTerm] = (present.find(([key, val]) => {
        return key === 'filter.search' && typeof val === 'string';
      }) ?? []) as [string, string];
      if (searchTerm) {
        pushGtm('searched', { searchTerm });
      }

      [...added, ...changed].forEach(([key, val]) => {
        let filterMetricValue = 1;
        let filterInfo: string | undefined;
        if (Array.isArray(val)) {
          // week detection
          if (
            val.length === 2 &&
            val.every((v) => Number.isInteger(v)) &&
            val[0] <= 52 &&
            val[1] >= 2000
          ) {
            filterMetricValue = 1;
            filterInfo = `${val[0]}, ${val[1]}`;
          } else {
            filterMetricValue = val.length;
          }
        }
        pushGtm('filtered', {
          label: key,
          value: filterMetricValue,
          ...(filterInfo ? { info: filterInfo } : null),
          searchTerm,
        });
      });
      removed.forEach(([key]) => {
        pushGtm('filtered', {
          label: key,
          value: 0,
          searchTerm,
        });
      });
    };

    const pushGtmOnScroll = (scroll: number, dir?: 'left' | 'right') => {
      pushGtm(`scrolled`, {
        value: scroll,
        info: dir,
      });
    };

    return {
      pushGtm,
      pushGtmOnScroll,
      pushGtmOnChangeFields,
    };
  }, [category, id, info, entity, value]);
};
