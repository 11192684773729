import type { RecursiveExtend } from '@pepper/types';
import { Theme } from '@chakra-ui/react';

/**
 * Used to color svgs
 * Generated using https://angel-rs.github.io/css-color-filter-generator/
 */
export const colorFilters = {
  gray: {
    900: 'brightness(0) saturate(100%) invert(24%) sepia(11%) saturate(308%) hue-rotate(185deg) brightness(92%) contrast(93%)',
    800: 'brightness(0) saturate(100%) invert(39%) sepia(3%) saturate(290%) hue-rotate(182deg) brightness(98%) contrast(94%)',
    700: 'brightness(0) saturate(100%) invert(51%) sepia(6%) saturate(600%) hue-rotate(190deg) brightness(98%) contrast(84%)',
    600: 'brightness(0) saturate(100%) invert(80%) sepia(4%) saturate(397%) hue-rotate(184deg) brightness(84%) contrast(93%)',
    500: 'brightness(0) saturate(100%) invert(79%) sepia(3%) saturate(358%) hue-rotate(187deg) brightness(96%) contrast(89%)',
    400: 'brightness(0) saturate(100%) invert(95%) sepia(1%) saturate(5242%) hue-rotate(181deg) brightness(92%) contrast(90%)',
    300: 'brightness(0) saturate(100%) invert(95%) sepia(9%) saturate(55%) hue-rotate(187deg) brightness(94%) contrast(89%)',
    200: 'brightness(0) saturate(100%) invert(96%) sepia(5%) saturate(82%) hue-rotate(201deg) brightness(100%) contrast(85%)',
    100: 'brightness(0) saturate(100%) invert(87%) sepia(6%) saturate(35%) hue-rotate(169deg) brightness(109%) contrast(95%)',
    50: 'brightness(0) saturate(100%) invert(91%) sepia(6%) saturate(591%) hue-rotate(162deg) brightness(117%) contrast(96%)',
  },
  red: {
    500: 'brightness(0) saturate(100%) invert(19%) sepia(100%) saturate(3662%) hue-rotate(353deg) brightness(74%) contrast(127%)',
  },
  orange: {
    700: 'brightness(0) saturate(100%) invert(59%) sepia(34%) saturate(2558%) hue-rotate(356deg) brightness(97%) contrast(94%)',
  },
  yellow: {
    500: 'brightness(0) saturate(100%) invert(67%) sepia(75%) saturate(474%) hue-rotate(352deg) brightness(88%) contrast(89%)',
  },
  blue: {
    500: 'brightness(0) saturate(100%) invert(20%) sepia(33%) saturate(7376%) hue-rotate(208deg) brightness(94%) contrast(106%)',
  },
  pepperBlack:
    'brightness(0) saturate(100%) invert(8%) sepia(4%) saturate(1713%) hue-rotate(188deg) brightness(90%) contrast(87%)',
};

/**
 * @see https://www.figma.com/file/1pTB216ruZ3nLDU83X08YR/Pepper-Design-System?type=design&node-id=4353-9733&t=Oh1H7SuBuwzUdZbO-0
 */
export const colors: RecursiveExtend<Theme>['colors'] & {
  red20: string;
  pepperBlack: string;
  tableGray: object;
  filters: typeof colorFilters;
} = {
  red20: '#FFEDED',
  filters: colorFilters,
  pepperBlack: '#202124',
  blackAlpha: {
    '500': '#20212472',
  },
  tableGray: {
    700: '#626365',
    600: '#7D808B',
    500: '#A6A9B0',
    400: '#BBBDC3',
    300: '#CBD5E0',
    200: '#DADBDE',
    100: '#E5E5E8',
  },
  gray: {
    900: '#3C3E43',
    800: '#626365',
    700: '#7D808B',
    600: '#A6A9B0',
    500: '#BBBDC3',
    400: '#CBD5E0',
    300: '#DADBDE',
    200: '#E5E5E8',
    100: '#EFF0F1',
    50: '#F9FAFA',
  },
  blue: {
    700: '#042F6D',
    600: '#0747A6',
    500: '#0052CC',
    400: '#0065FF',
    300: '#4C9AFF',
    200: '#B3D4FF',
    100: '#DEEBFF',
    50: '#F2F7ff',
  },
  green: {
    700: '#006644',
    600: '#00875A',
    500: '#36B37E',
    400: '#57D9A3',
    300: '#79F2C0',
    200: '#ABF5D1',
    100: '#E3FCEF',
  },
  red: {
    700: '#920000',
    600: '#B90000',
    500: '#CD0000',
    400: '#FF0909',
    300: '#FF6B6B',
    200: '#FF9292',
    100: '#FFB9B9',
    50: '#FFE1E1',
  },
  orange: {
    700: '#F0890D',
    600: '#F49B30',
    500: '#F6AD55',
    400: '#F8BF7A',
    300: '#FAD1A0',
    200: '#FCE3C5',
    100: '#FEF5EA',
  },
  purple: {
    700: '#403294',
    600: '#5243AA',
    500: '#6554C0',
    400: '#8777D9',
    300: '#998DD9',
    200: '#C0B6F2',
    100: '#EAE6FF',
  },
  teal: {
    700: '#008DA6',
    600: '#00A3BF',
    500: '#00B8D9',
    400: '#00C7E6',
    300: '#79E2F2',
    200: '#B3F5FF',
    100: '#E6FCFF',
  },
  pink: {
    700: '#CC11A7',
    600: '#E513BC',
    500: '#FF2DD5',
    400: '#FF5BDE',
    300: '#FF8AE8',
    200: '#FFB9F1',
    100: '#FFE7FA',
  },
  yellow: {
    700: '#975A16',
    600: '#B7791F',
    500: '#D69E2E',
    400: '#ECC94B',
    300: '#F6E05E',
    200: '#FAF089',
    100: '#FEFCBF',
  },
  cyan: {
    700: '#0D47A1',
    600: '#1976D2',
    500: '#2196F3',
    400: '#64B5F6',
    300: '#90CAF9',
    200: '#BBDEFB',
    100: '#E3F2FD',
  },
};

export const gradientColors = {
  green: 'linear-gradient(180deg, #57D9A3 0%, #00875A 100%)',
  blue: 'linear-gradient(180deg, #4C9AFF 0%, #0052CC 100%)',
  yellow: 'linear-gradient(180deg, #D69E2E 0%, #975A16 100%)',
};
