/* eslint-disable */
/* This is a generated file (see scripts/schema.js), don't edit manually */
export const vendorSchemaFields = [
  {
    "name": "id",
    "type": "STRING",
    "description": "Unique id using combination of GEID and VendorId. Is equal to 'globalEntityId;vendorId'"
  },
  {
    "name": "vendorId",
    "type": "STRING",
    "description": "DH Vendor ID"
  },
  {
    "name": "globalEntityId",
    "type": "STRING",
    "description": "DH GlobalEntityId"
  },
  {
    "name": "partnerId",
    "type": "STRING",
    "description": "Pepper Partner ID"
  },
  {
    "name": "stallIds",
    "type": "STRING",
    "mode": "REPEATED",
    "description": "Stall IDs where vendor is currently active in. Will be empty if vendor is not active."
  },
  {
    "name": "status",
    "type": "STRING",
    "description": "Enum: 'ACTIVE'/'INACTIVE'"
  },
  {
    "name": "goLiveDate",
    "type": "TIMESTAMP",
    "mode": "NULLABLE",
    "description": "Go live date in current facility, default is based on platform's activation date."
  },
  {
    "name": "label",
    "type": "STRING",
    "description": "Custom name of the vendor"
  },
  {
    "name": "events",
    "type": "RECORD",
    "mode": "REPEATED",
    "fields": [
      {
        "name": "type",
        "type": "STRING",
        "description": "Enum: 'Onboarding'/'Offboarding'"
      },
      {
        "name": "stallId",
        "type": "STRING",
        "description": "Pepper Stall ID"
      },
      {
        "name": "createdAt",
        "type": "TIMESTAMP",
        "description": "Time of event"
      },
      {
        "mode": "NULLABLE",
        "name": "comment",
        "type": "STRING",
        "description": "Comment"
      },
      {
        "name": "reasons",
        "type": "STRING",
        "mode": "REPEATED",
        "description": "Reasons for Offboarding. Enum:'ChurnInOnboarding'/CuisineNotFit'/'ForceChurn'/'NegativePnl/'PartnerHasOperationalIssue'/'PartnerOpeningOwnStore'/'PartnerMovingFacility'/'PartnerMovingStall'/'SwitchingStallWrongOnboarding'/'AccidentalOffboarding'/'Other'. Notes for BI team: AccidentalOffboarding is a case when you can ignore the offboarding and next onboarding event. SwitchingStallWrongOnboarding is a case when you can ignore prev onboarding and the current offboarding, the next onboarding event should be considered as a correct one."
      }
    ]
  },
  {
    "name": "facilityId",
    "type": "STRING",
    "description": "Pepper Facility ID of the last active facility of vendor."
  },
  {
    "name": "facilityType",
    "type": "STRING",
    "description": "Facility Type Enum: 'Store'/'Kitchen'"
  },
  {
    "name": "settings",
    "type": "RECORD",
    "mode": "NULLABLE",
    "fields": [
      {
        "name": "orderStatusChange",
        "type": "STRING",
        "description": "Order status change. Enum: 'Automatic'/'External'/'Manual'/'Pepper'"
      }
    ]
  },
  {
    "name": "createdAt",
    "type": "TIMESTAMP",
    "description": "When vendor was added in Pepper"
  },
  {
    "name": "updatedAt",
    "type": "TIMESTAMP",
    "description": "When vendor was last updated in Pepper"
  },
  {
    "name": "exportedAt",
    "type": "TIMESTAMP",
    "description": "When vendor was last exported from Pepper"
  }
] as const;
export type VendorSchema = typeof vendorSchemaFields;